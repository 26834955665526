export const Base_Url = "https://baithna.tribandtech.com:7073/"

export const urls = {
    chart: {
       getSubjectList:"get_columns_names",
       getIndicatorsList:"get_table_names",
       getCountrynamesList:"get_country_names",
       countryNameListbyId:"get_country_subgroup_names",
       getMaps:"trendline",
       getYears:"get_years"
      },
}