import { ToastContainer, toast } from "react-toastify"
import instance from "../services"

export const notify = (status, msg) => {
    if (status == true) {
            toast.success(msg)
            return (<ToastContainer />)
        } else {
            toast.error(msg)
            return (<ToastContainer />)
        }
    }


export const save = async (url, data) => {
    try {
        let res = await instance.post(url, data);
       
        if (res?.data?.status == true) {
           
            notify(res?.data?.status, res?.data?.message)
           
            return res
        } else {
            notify(res?.data?.status, res?.data?.message)
            return {}
        }
    } catch (e) {
       
        return {}
    }
}



export const getById = async (url, data) => {
    try {
        const res = await instance.post(url, data);
        if (res?.data?.status == true) {
            return res?.data?.data
        } else {
            return {}
        }
    } catch (e) {
        return {}
    }
}


export const getAll = async (url) => {
    try {
        let res = await instance.get(url);
        
        return res.data.data;
    } catch (e) {

        return {}
    }
}



export const getList = async (url, data) => {
    try {
        const res = await instance.post(url, data);
        if (res?.data?.length > 0) {
            return res?.data
        } else {
            return []
        }
    } catch (e) {
        return []
    }
}
