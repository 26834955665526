import React, { useEffect, useState } from 'react'
import { IoGlobeOutline } from "react-icons/io5";
import { MdOutlineShowChart } from "react-icons/md";
import { LuScatterChart } from "react-icons/lu";
import { AiOutlineLineChart } from "react-icons/ai";
import { urls } from '../configFiles/urls';
import { getAll, getList, save } from '../configFiles/Sharedcomponents';
import { Accordion, Alert } from 'react-bootstrap';
import logo1 from "../assets/images/phm.png";
import Select from "react-select";


const LandingPage = () => {
    const [data, setData] = useState({})
    const [searchSubjects, setSearchSubjects] = useState("")
    const [filterSubjects, setFilterSubjects] = useState([])
    const [indicators, setIndicators] = useState([])
    const [searchIndicators, setSearchIndicators] = useState("")
    const [allsubjects, setAllsubjects] = useState([])
    const [allIndicators, setAllIndicators] = useState([])
    const [maps, setMaps] = useState([])
    const [years, setYears] = useState([])
    console?.log(maps, "maps12121")
    const [state, setState] = useState("Linechart")
    const [countries, setCountries] = useState("Countries")
    const [countrynames, setcountrynames] = useState([{ selected: false, id: "1", countryName: "Albania" }, { selected: false, id: "2", countryName: "Andorra" }, { selected: false, id: "3", countryName: "Armenia" }, { selected: false, id: "4", countryName: "Austria" }, { selected: false, id: "5", countryName: "Azerbaijan" },
    { selected: false, id: "6", countryName: "Belarus" }, { selected: false, id: "7", countryName: "Belgium" }, { selected: false, id: "8", countryName: "Bosnia and Herzegovina" }, { selected: false, id: "9", countryName: "Bulgaria" }, { selected: false, id: "10", countryName: "Croatia" },
    { selected: false, id: "11", countryName: "Cyprus" }, { selected: false, id: "12", countryName: "Czechia" }, { selected: false, id: "13", countryName: "Denmark" }, { selected: false, id: "14", countryName: "Estonia" }, { selected: false, id: "15", countryName: "Finland" }, { selected: false, id: "16", countryName: "France" },
    { selected: false, id: "17", countryName: "Georgia" }, { selected: false, id: "18", countryName: "Germany" }, { selected: false, id: "19", countryName: "Greece" }, { selected: false, id: "20", countryName: "Hungary" }, { selected: false, id: "21", countryName: "Iceland" }, { selected: false, id: "22", countryName: "Ireland" },
    { selected: false, id: "23", countryName: "Israel" }, { selected: false, id: "24", countryName: "Italy" }, { selected: false, id: "25", countryName: "Kazakhstan" }, { selected: false, id: "26", countryName: "Kyrgyzstan" }, { selected: false, id: "27", countryName: "Latvia" }, { selected: false, id: "28", countryName: "Lithuania" },
    { selected: false, id: "29", countryName: "Luxembourg" }, { selected: false, id: "30", countryName: "Malta" },
    { selected: false, id: "31", countryName: "Monaco" }, { selected: false, id: "32", countryName: "Montenegro" },
    { selected: false, id: "33", countryName: "Netherlands" }, { selected: false, id: "34", countryName: "North Macedonia" },
    { selected: false, id: "35", countryName: "Norway" }, { selected: false, id: "36", countryName: "Poland" },
    { selected: false, id: "37", countryName: "Portugal" }, { selected: false, id: "38", countryName: "Republic of Moldova" },
    { selected: false, id: "39", countryName: "Romania" }, { selected: false, id: "40", countryName: "Russian Federation" },
    { selected: false, id: "41", countryName: "San Marino" }, { selected: false, id: "42", countryName: "Serbia" },
    { selected: false, id: "43", countryName: "Slovakia" }, { selected: false, id: "44", countryName: "Slovenia" },
    { selected: false, id: "45", countryName: "Spain" }, { selected: false, id: "46", countryName: "Sweden" },
    { selected: false, id: "47", countryName: "Switzerland" }, { selected: false, id: "48", countryName: "Tajikistan" },
    { selected: false, id: "49", countryName: "Türkiye" }, { selected: false, id: "50", countryName: "Turkmenistan" },
    { selected: false, id: "51", countryName: "Ukraine" }, { selected: false, id: "52", countryName: "United Kingdom" },
    { selected: false, id: "53", countryName: "Uzbekistan" }
    ])
    const [updatedCountryNames, setupdatedCountryNames] = useState([])
    const [countrygroups, setCountrygroups] = useState([
        { selected: false, id: "1", name: "WHO European Region" }, { selected: false, id: "2", name: "Members of the European Union" }, { selected: false, id: "3", name: "Members of the EU before May 2004 (EU15)" }, { selected: false, id: "4", name: "Members of the EU after May 2004 (EU13)" },
        { selected: false, id: "5", name: "Members of the EU before Feb 2020" }, { selected: false, id: "6", name: "Commonwealth of Independent States" }, { selected: false, id: "7", name: "Central Asian Republics Information Network members (CARINFONET)" },
        { selected: false, id: "8", name: "South-eastern Europe Health Network members (SEEHN)" }, { selected: false, id: "9", name: "Nordic countries" }, { selected: false, id: "10", name: "Small countries" }, { selected: false, id: "11", name: "HBSC countries" },
        { selected: false, id: "12", name: "WHO Euro surveillance countries" }, { selected: false, id: "13", name: "Western Balkans" }
    ])

    const classFun = (value) => {
        return state == value ? " text-center  btn btn-bg " : " text-center btn bg-white btn-text"
    }


    const classFun1 = (value) => {
        return countries == value ? " col-md-6 text-center btn btn-bg" : "col-md-6 text-center btn bg-white btn-text"
    }
console?.log(data,"handleChangeSearch")
    const handleChangeSearch = (name) => (selectedOption) => {
   
        if (name) {
          let str = selectedOption!=null?selectedOption.value:"1";
          setData({
            ...data,
            [name]: str,
          });
        }
      };

    const handleRadioButton = (key) => (e) => {
        let str = e.target.value

        if (str != "") {
            setData({ ...data, [key]: str })
        }
    }


    const handleCheckBox = (key) => (e) => {
        let str = e.target.checked ? key : ""
        console?.log(e.target.value, str, "e.target.value", key)
        setData({
            ...data,
            [key]: str
        })
    }


    const returnOptionValue = (array, key, label, value) => {
        let filterObject = (array?.filter((v) => v?.[key] == data?.[value]))[0]
        
        return filterObject?.[label]!=undefined?{ label: filterObject?.[label], value: filterObject?.[value] }:"" 
      }

    console?.log(data, "key", data?.MDB)

    const handlecheckbox = (id, arraylist, key) => {

        const subjects = arraylist?.map((val) =>
            val.id === id
                ? { ...val, selected: !val.selected }
                : val
        );
        const array = subjects?.filter((val) => val?.selected == true)?.map((v) => {
            return (key == "country" || key == "Countrygroups" ? key == "Countrygroups" ? v.name : v.countryName : { subjectName: v?.indicator_name, filename: v?.short_name })
        })
        switch (key) {
            case "subject": return setFilterSubjects(subjects), id ? getIndicatorslist(array) : setIndicators([])
            case "country": return setcountrynames(subjects), id ? setMaps(array) : setMaps([])

            case "Countrygroups": return setCountrygroups(subjects), data?.indicator && id ? getCountryNameListById(array) : setMaps([])
            default: return key

        }

    }

    const AllCheckBox = (id, arraylist, key, contryArray) => {
        const subjects = arraylist?.map((v) => ({
            ...v,
            selected: id
        }))

        const array = subjects?.map((v) => {

            return (key == "country" || key == "Countrygroups" ? key == "Countrygroups" ? v.name : v.countryName : { subjectName: v?.indicator_name, filename: v?.short_name })


        })

        const country = countrygroups?.map((v) => ({
            ...v,
            selected: id
        }))

        const country1 = countrynames?.map((v) => ({
            ...v,
            selected: id
        }))



        switch (key) {
            case "subject": return setFilterSubjects(subjects), id ? getIndicatorslist(array) : setIndicators([]), setAllIndicators([])
            case "country": return setcountrynames(subjects), setCountrygroups(country), id ? setMaps(array) : setMaps([])
            case "Countrygroups": return setCountrygroups(subjects), setcountrynames(country1), data?.indicator && id ? getCountryNameListById(array) : setMaps([])
            // return setCountrygroups(subjects), data?.indicator&&getCountryNameListById(array)
            default: return key
        }

    }


    const getSubjectslist = async () => {
        // const payload = [
        //     data?.HFA != null ? data?.HFA : "",
        //     data?.MDB != null ? data?.MDB : "",
        //     data?.HRes != null ? data?.HRes : ""
        //   ].filter(value => value !== "");
        let res = await getList(urls?.chart?.getSubjectList, {
            data:
                [data?.HFA != null ? data?.HFA : "", data?.MDB != null ? data?.MDB : "", data?.HRes != null ? data?.HRes : ""]
        })
        res?.map((val) => {
            val["selected"] = false
        })

        setFilterSubjects(res)
        setAllsubjects(res)

    }
    const getIndicatorslist = async (arrayOf) => {
        let data = arrayOf
        let res = await getList(urls?.chart?.getIndicatorsList, { data })
        setIndicators(res)
        setAllIndicators(res)
        // setData({})

    }
    const getYearslist = async () => {
        
        let res = await getList(urls?.chart?.getYears, { data :  data?.indicator})
        
        data["id"]=res[0]?.Year
        setYears(res)

    }



    const getCountryNameListById = async (str) => {

        if (str && str?.length > 0) {
            let res = await getList(urls?.chart?.countryNameListbyId, { data: { tablename: data?.indicator, subgroupcountryname: str } })
            let res2 = res
            let array = res2?.map((v) => { return v?.name })
            const subjects = countrynames?.map((val) => array?.includes(val.countryName) ? { ...val, selected: true }
                : { ...val, selected: false }
            );
            setcountrynames(subjects)
            const array1 = subjects?.filter((val) => val?.selected == true)?.map((v) => {
                return (v?.countryName)
            })
            setMaps(array1)

        }
        else {
            countrynames?.map((val) => {
                return val["selected"] = false
            });

            setcountrynames(countrynames)

        }




    }
    const countrygroups1 = [
        { name: "One color for all countries" }, { name: "Members of the European Union" }, { name: "Members of the EU before May 2004 (EU15)" }, { name: "Members of the EU after May 2004 (EU13)" },
        { name: "Members of the EU before Feb 2020" }, { name: "Commonwealth of Independent States" }, { name: "Central Asian Republics Information Network members (CARINFONET)" },
        { name: "South-eastern Europe Health Network members (SEEHN)" }, { name: "Nordic countries" }, { name: "Small countries" }, { name: "HBSC countries" },
        { name: "WHO Euro surveillance countries" }, { name: "Western Balkans" }
    ]

    console?.log("data?.indicator", data?.indicator)

    useEffect(() => {
        if (data?.HFA || data?.MDB || data?.HRes) {
            getSubjectslist()
            setIndicators([])
            data["indicator"] = {}

        }
        else {
            // setData({})
            setFilterSubjects([])
        }

    }, [data?.HFA, data?.MDB, data?.HRes])


    useEffect(() => {
        if(data&&data?.indicator?.length>0){
            getYearslist()
        }
       
        
    }, [data&&data?.indicator?.length>0])


    useEffect(() => {
        if (searchSubjects?.trim() === '') {
            setFilterSubjects(allsubjects);
        } else {

            const result = filterSubjects?.filter((v) => {
                return (v?.indicator_name?.toString()?.toLowerCase()?.includes(searchSubjects?.toLowerCase()))

            })

            setFilterSubjects(result)
        }

    }, [searchSubjects])


    useEffect(() => {
        if (searchIndicators?.trim() === '') {
            setIndicators(allIndicators);
        } else {

            const result = indicators.filter((v) => {
                return (v?.indicators?.toString()?.toLowerCase()?.includes(searchIndicators?.toLowerCase())
                )

            })

            setIndicators(result)
        }

    }, [searchIndicators])
    console?.log(`https://baithna.tribandtech.com:7073/geograph/${data?.indicator}/${data?.id}`, "filterSubjects&&filterSubjects?.length>0", maps)
    return (
        <div className='row screen  p-2' style={{ height: "100vh" }}>
            <div className='col-md-3   scroll mt-4' style={{ height: "94vh" }}>


                <div className=' row  px-1 '>
                    {state == "Map" && <div className='col-md-12'></div>}
                    <div
                        className={'col-md-12 '}
                    >
                        <div class="card border  " >
                            <div class="card-body " >
                                <p className='text-center header'>Choose indicators</p >
                                <p className='body'>
                                    Select one or more indicator to visualize data.

                                    Filter through subjects or pick a database you are interested in.
                                </p>
                                <div className='col-md-12 d-flex justify-content-around body'>
                                    <div>
                                        <input
                                            class="form-check-input "
                                            id={"HFA"}
                                            type='checkbox'
                                            onChange={handleCheckBox("HFA")}
                                        />
                                        <label className='form-check-label' for={"HFA"}>HFA</label>
                                    </div>
                                    <div>
                                        <input
                                            class="form-check-input "
                                            id={"MDB"}
                                            type='checkbox'
                                            onChange={handleCheckBox("MDB")}

                                        />
                                        <label className='form-check-label ' for={"MDB"}>MDB</label>
                                    </div>
                                    <div>
                                        <input
                                            class="form-check-input "
                                            id={"HRes"}
                                            type='checkbox'
                                            onChange={handleCheckBox("HRes")}

                                        />
                                        <label className='form-check-label ' for={"HRes"}>HRes</label>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    {state == "Map" && <div className='col-md-12'></div>}
                    <div
                        className={'col-md-12 '}
                    >
                        <div class="card border  mt-2 " >
                            <div class="card-body " >
                                <p className='header text-center'>Pick a subject</p>

                                <input className='form-control  mt-2 mb-2'
                                    style={{ background: "#FAFAFA" }}
                                    value={searchSubjects}
                                    onChange={(e) => setSearchSubjects(e.target.value)}
                                    placeholder='Search items..' />
                                <div className='body scroll' style={{ height: filterSubjects && filterSubjects?.length > 0 ? "250px" : "" }}>
                                    {filterSubjects?.map((v, i) => {
                                        return (
                                            <div className='form-check'>
                                                <input
                                                    class="form-check-input "
                                                    id={v?.id}
                                                    checked={v?.selected}
                                                    type='checkbox'
                                                    onClick={() => handlecheckbox(v?.id, filterSubjects, "subject")} />
                                                <label className='form-check-label ' for={v?.id}>{v.indicator_name}{" "}(<span>{v?.short_name}</span>)</label>

                                            </div>
                                        )
                                    })}

                                </div>
                                {filterSubjects && filterSubjects != "" ? <div className='d-flex gap-2 px-4 py-2'>
                                    <button className='col-md-6 text-center btn btn-bg' onClick={() => AllCheckBox(true, filterSubjects, "subject")}>Select all </button>
                                    <button className='col-md-6 text-center  btn btn-text bg-white ' onClick={() => AllCheckBox(false, filterSubjects, "subject")}>Deselect all</button>

                                </div> : <span className='text-center'>No data found</span>}
                            </div>



                        </div>
                    </div>
                    {state == "Map" && <div className='col-md-12'></div>}
                    <div
                        className={'col-md-12 '}
                    >
                        <div class="card border  mt-2" >
                            <div class="card-body " >
                                <p className='header text-center'>Selected indicators</p>
                                <input className='form-control  mb-2' value={searchIndicators}
                                    style={{ background: "#FAFAFA" }}

                                    onChange={(e) => setSearchIndicators(e.target.value)}
                                    placeholder='Search items..' />
                                <div className='body scroll py-2' style={{ height: indicators && indicators?.length > 0 ? "240px" : "" }}>

                                    {indicators?.length > 0 ? indicators?.map((v, i) => {
                                        return (

                                            <div className="form-check ">
                                                <input
                                                    className="form-check-input " type="radio"
                                                    value={v?.indicators}
                                                    checked={data?.indicator == v?.indicators ? true : false}
                                                    onChange={handleRadioButton("indicator")}
                                                    id={`${'Yes' + i}`}
                                                />
                                                <label className=' form-check-label ' for={`${'Yes' + i}`}>{v?.indicators}{" "}(<span>{v?.filename}</span>)</label>
                                            </div>
                                        )
                                    }) : "No data found"}

                                </div>
                            </div>
                        </div>
                    </div>

                    {(state == "Linechart" || state == "Bubblechart") &&
                        <div className='col-md-12 mt-2'>
                            <div class="card "  >
                                <div class="card-body " >
                                    <p className='text-center header'>Countries {maps?.length}/{countrynames?.length}</p >
                                    <p className='body'>
                                        Select a country, multiple countries or a group of countries in the “country groups”.
                                    </p>
                                    <div >
                                        <input className='form-control mb-2 ' value={""}
                                            style={{ background: "#FAFAFA" }}
                                            onChange={""}
                                            placeholder='Search items..' />
                                    </div>

                                    <div className='d-flex gap-1'>
                                        <button className={classFun1("Countries")} onClick={() => setCountries("Countries")}>Countries </button>
                                        <button className={classFun1("Countrygroups")} onClick={() => setCountries("Countrygroups")}>Country groups</button>
                                    </div>
                                    <hr />
                                    {countries == "Countries" && <div className='scroll body' style={{ height: "200px" }}>
                                        {countrynames?.map((v => {
                                            return (

                                                <div className='form-check'>
                                                    <input
                                                        class="form-check-input "
                                                        id={v?.countryName}
                                                        checked={v?.selected}
                                                        type='checkbox'
                                                        onClick={() => handlecheckbox(v?.id, countrynames, "country")} />
                                                    <label className='form-check-label ' for={v?.countryName}>{v?.countryName}</label>

                                                </div>
                                            )
                                        }))}
                                    </div>}
                                    {countries == "Countrygroups" && <div className='scroll body' style={{ height: "200px" }}>
                                        {countrygroups?.map((v => {
                                            return (
                                                <div className='form-check'>
                                                    <input
                                                        class="form-check-input "
                                                        id={v?.name}
                                                        checked={v?.selected}
                                                        type='checkbox'
                                                        onClick={() => handlecheckbox(v?.id, countrygroups, "Countrygroups")} />
                                                    <label className='form-check-label ' for={v?.name}>{v?.name}</label>

                                                </div>
                                            )
                                        }))}
                                    </div>}
                                    <hr />

                                    <div className='d-flex gap-2 '>
                                        <button className='col-md-6 text-center btn btn-bg' onClick={() => AllCheckBox(true, countries != "Countrygroups" ? countrynames : countrygroups, countries != "Countrygroups" ? "country" : "Countrygroups")}>Select all </button>
                                        <button className='col-md-6 text-center  btn bg-white btn-text' onClick={() => AllCheckBox(false, countries != "Countrygroups" ? countrynames : countrygroups, countries != "Countrygroups" ? "country" : "Countrygroups")}>Deselect all</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {/* {(state == "Linechart" || state == "Bubblechart") &&
                        <div className='col-md-12 mt-2'>
                            <div class="card "  >
                                <div class="card-body text-center" >
                                    <p className='header'>Show average value (1/13)</p>
                                    <p className='body'>Show average value for countries within the following country groups:</p>
                                    <hr />

                                </div>
                                <div className=' scroll body' style={{ height: "200px" }}>
                                    {countrygroups?.map((v => {
                                        return (
                                            <div>
                                                <input type='checkbox'></input>
                                                <label>{v?.name}</label>
                                            </div>
                                        )
                                    }))}

                                </div>
                                <hr />
                                <div className='d-flex gap-2 px-4 mb-4'>
                                    <button className='col-md-6 text-center btn btn-bg' >Select all </button>
                                    <button className='col-md-6 text-center  btn bg-white btn-text' >Deselect all</button>
                                </div>


                            </div>

                            {state == "Bubblechart" && <div className='mt-2'>
                                <div class="card  "  >
                                    <div class="card-body text-center" >
                                        <h5 >Highlight options</h5>
                                        <p >Use highlight options to mark a country group.</p>

                                    </div>
                                    <div className='px-2' style={{ height: "280px", overflowY: "auto" }}>
                                        {countrygroups1?.map((v => {
                                            return (
                                                <div>
                                                    <input type='checkbox'></input>
                                                    <label>{v?.name}</label>
                                                </div>
                                            )
                                        }))}
                                    </div>


                                </div>
                            </div>}
                        </div>}

 */}



                </div>
            </div>
            <div className='col-md-9'>


                <div className='row mt-2'>
                    <div className='col-md-3'></div>
                    <div className='row col-md-6 d-flex justify-content-around' >

                        <div className='row text-center '>
                            <img className="imgstyle  mx-auto d-block" src={logo1} />
                            <p className='header'>Choose the type of chart</p>
                            <p className='body'>Pick a line chart, bubble chart or a map.</p>
                        </div>
                        <div className={classFun("Linechart")} onClick={() => setState("Linechart")}><div>Line chart</div></div>
                        <div className={classFun("Bubblechart")} onClick={() => setState("Bubblechart")}><div>Bubble chart</div></div>
                        <div className={classFun("Map")} onClick={() => setState("Map")}><div>Map</div></div>

                    </div>
                    <div className='col-md-3'></div>
                </div>
                {state == "Map" && <div className='d-flex justify-content-end '>
                    <Select
                        className={"col-md-2"}
                          value={returnOptionValue(
                            years,
                            "Year",
                            "Year",
                            "id"
                          )}
                        name="Years"
                      onChange={handleChangeSearch("id")}
                      options={years?.map((opt) => ({
                        label: opt?.Year,
                        value: opt?.Year,
                      }))}
                    />
                </div>}
                {state == "Linechart" && <iframe width="100%" height="500" src={`https://baithna.tribandtech.com:7073/trendline/${data?.indicator}/[${maps?.map((v) => { return `"${v}"` })}]`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                }
                {state == "Bubblechart" && <iframe width="100%" height="500" src={`https://baithna.tribandtech.com:7073/scatter/${data?.indicator}/[${maps?.map((v) => { return `"${v}"` })}]`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                }
                {state == "Map" && <iframe width="100%" height="500" src={`https://baithna.tribandtech.com:7073/geograph/${data?.indicator}/${data?.id}`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                }
            </div>
        </div>
    )
}

export default LandingPage
